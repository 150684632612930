import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';



@Directive({
    selector: '[appLowercaseEmail]'
})
export class LowercaseEmailDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input') onInput() {
        this.el.nativeElement.value = this.el.nativeElement.value.toLowerCase();
    }
}
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NgbdAdvancedSortableHeader } from 'src/app/shared/directives/sortable.directive';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reassign-reviewers',
  templateUrl: './reassign-reviewers.component.html',
  styleUrls: ['./reassign-reviewers.component.scss']
})
export class ReassignReviewersComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  total!: number;
  @ViewChildren(NgbdAdvancedSortableHeader)
  headers!: QueryList<NgbdAdvancedSortableHeader>;
  page = 1;
  pageSize = 20;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  econtent: any = {};
  lists: any[] = [];
  masterSelected!: boolean;
  currentUser: any;


  constructor(
    public userService: UserMgtService,
    private utilService: UtilService,
    private applicationService: ApplicationService,
    private sessionService: SessionService
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    
  }
  ngOnDestroy() {
    this.userService.searchTerm = '';
  }


  loadList() {
    this.lists = this.inputData.reviewers;
    this.content = this.lists;
    setTimeout(() => {
      document.getElementById("elmLoader")?.classList.add("d-none");
    }, 1200);
    this.collectionSize = this.lists.length;
    this.total = this.lists.length;
    this.totalRecords = this.lists.length;
    this.startIndex = (this.page - 1) * this.pageSize + 1;
    this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.lists = this.lists.slice(
      this.startIndex - 1,
      this.endIndex
    );
  }



  getPremiumData() {
    this.lists = this.content
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  checkedValGet: any[] = [];
  checkUncheckAll(ev: any) {
    this.lists.forEach((x: { state: any }) => (x.state = ev.target.checked));
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }

    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }


  onCheckboxChange(e: any) {
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    this.inputData.userId = this.checkedValGet[this.checkedValGet.length - 1]?.userId;
    // checkedVal.length > 0
    //   ? ((
    //     document.getElementById("remove-actions") as HTMLElement
    //   ).style.display = "block")
    //   : ((
    //     document.getElementById("remove-actions") as HTMLElement
    //   ).style.display = "none");
  }



  ngOnInit() {
    
    this.loadList();
  }




  submit(event: any) {
    if (this.checkedValGet.length === 0) {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "Atleast select one user to proceed", "bottom", "center")
    } else {
      let dataArray: any[] = []
      this.checkedValGet.forEach(element => {
        dataArray.push(element.id);
      });
      this.applicationService.deleteWithPathNoId(environment.applicationUrl + 'application/reassignReviewer/' + this.inputData.applicationId +
        '/reassign-review-user/' + this.inputData.userId
      )
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Application re-assigned successfully", "bottom", "center");
            this.backToParent.emit(true);
          }
        )
    }
  }


  removeReviewer(event: any) {
    if (this.checkedValGet.length === 0) {
      this.utilService.showNotification(NOTIFICATION_COLOR.error, "At least select one user to proceed", "bottom", "center")
    } else {
      let dataArray: any[] = []
      this.checkedValGet.forEach(element => {
        dataArray.push(element.id);
      });
      this.applicationService.deleteWithPathNoId(environment.applicationUrl + 'application/removeReviewer/' + this.inputData.applicationId +
        '/remove-review-user/' + this.inputData.userId
      )
      // this.applicationService.deleteWithPathNoId(environment.applicationUrl + 'application/removeReviewer/' + this.inputData.applicationId +
      //   '/remove-review-user/' + this.inputData.userId
      // )
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Application re-assigned successfully", "bottom", "center");
            this.backToParent.emit(true);
          }
        )
    }
  }



  close() {
    this.backToParent.emit(false);
  }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { ApplicationService } from 'src/app/application/services/application.service';
import { StepperService } from 'src/app/application/services/stepper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-info-application',
  templateUrl: './new-info-application.component.html',
  styleUrls: ['./new-info-application.component.scss']
})
export class NewInfoApplicationComponent {
  paramsId: any = {};
  mapOptions: google.maps.MapOptions = {
    center: { lat: 38.9987208, lng: -77.2538699 },
    zoom: 14
  }
  marker = {
    position: { lat: 38.9987208, lng: -77.2538699 },
  }

  applicationDetail: any = {};
  userForm!: UntypedFormGroup;

  constructor(
    private cdr: ChangeDetectorRef,
    private stepperService: StepperService,
    private appConfig: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder) {
    this.applicationDetail = {}
    this.applicationDetail = JSON.parse(localStorage.getItem(this.appConfig.UPI_NEW_INFO) as any);
    // if (this.applicationDetail) {

    // }

    this.route.params.subscribe((params: any) => {
      this.paramsId = params.id;
      if (this.paramsId !== '0') {
        this.applicationDetail = {}
        this.checkIfProjectExits(this.paramsId);
      } else {
        this.applicationDetail = JSON.parse(localStorage.getItem(this.appConfig.UPI_NEW_INFO) as any);
        this.applicationDetail.isProjectAlreadyExists = false;
      }
    })
  }

  checkIfProjectExits(paramsId: any) {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/' + paramsId)
      .subscribe(
        data => {
          // this.applicationDetail = data;
          this.applicationDetail.id = data.id;
          this.applicationDetail.upi = data.upi;
          this.applicationDetail.isFromOldSystem = data.isFromOldSystem;
          this.applicationDetail.isUnderMortgage = data.isUnderMortgage;
          this.applicationDetail.isUnderRestriction = data.isUnderRestriction;
          this.applicationDetail.centralCoordinateX = data.centralCoordinateX;
          this.applicationDetail.centralCoordinateY = data.centralCoordinateY;
          this.applicationDetail.villageCode = data.villageCode;
          this.applicationDetail.villageName = data.villageName;
          this.applicationDetail.cellCode = data.cellCode;
          this.applicationDetail.cellName = data.cellName;
          this.applicationDetail.sectorCode = data.sectorCode;
          this.applicationDetail.sectorName = data.sectorName;
          this.applicationDetail.districtCode = data.districtCode;
          this.applicationDetail.districtName = data.districtName;
          this.applicationDetail.provinceCode = data.provinceCode;
          this.applicationDetail.provinceName = data.provinceName;
          this.applicationDetail.selectedUse = data.selectedUse;
          this.applicationDetail.selectedCategoryUse = data.selectedCategoryUse;
          this.applicationDetail.agencyId = data.agencyId;
          this.applicationDetail.projectName = data.projectName;
          this.applicationDetail.projectDescription = data.projectDescription;
          this.applicationDetail.plotSize = data.plotSize;
          this.applicationDetail.userId = data.userId;
          this.applicationDetail.created_at = data.created_at;
          this.applicationDetail.updated_at = data.updated_at;
          this.applicationDetail.isProjectAlreadyExists = true;
          this.userForm = this.formBuilder.group({
            upi: [this.applicationDetail?.upi, [Validators.required]],
          });
        }
      )
  }

  ngOnInit(): void {
    if (!this.applicationDetail?.isFromOldSystem
      && this.applicationDetail?.bpmisProjectId
      && this.applicationDetail?.bpmisProjectId.toString().length > 0) {
      this.onNext();
    } else {
      this.userForm = this.formBuilder.group({
        upi: [this.applicationDetail?.upi, [Validators.required]],
      });
    }

  }

  onPrev(): void {
    this.router.navigate(['/account/application/applications']);
    // Disable Previous button when current step is 1
    // if (this.stepperService.getCurrentStep() > 1) {
    //   this.stepperService.prevStep();
    // }
  }

  onNext(): void {
    // this.stepperService.nextStep();
    if (this.paramsId === '0') {
      this.router.navigate(['/account/application/new-application-project', 0]);

    } else {
      this.router.navigate(['/account/application/new-application-project', this.paramsId]);
    }

  }

  // isPrevButtonDisabled(): boolean {
  //   return this.stepperService.getCurrentStep() === 1;
  // }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AppConfig } from '../app.config';



export const UserData = [
  {
    "name": "Applicant",
    "code": "APP",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: true },
      { isAllowToSeeAllProjects: false },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeCertificate: true },
      { isAllowToSeeReport: false },
      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },

    ]
  },
  {
    "name": "Admin",
    "code": "ADM",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: false },
      { isAllowToSeeTransferedCertificate: true },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: true },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeCertificate: false },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: true },
      { isAllowToSeeGeneralApplicationReport: true },
      { isAllowToSeeFilteredApplicationReport: true },
      { isAllowToSeeDashboardReport: true },
      { isAllowToSeeChatBoardReport: true },
      { isAllowToSeeNonObjectionReport: true },
      { isAllowToSeeWeeklyReport: true },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Engineer",
    "code": "ENG",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: true },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Architect",
    "code": "ENG",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: true },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },
      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Staff",
    "code": "STF",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Reviewer",
    "code": "STF",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },


  {
    "name": "Sector",
    "code": "SCT",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: false },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: false },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: false },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: false },
      { isAllowToSeeReport: false },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },


  {
    "name": "Inspector",
    "code": "INSP",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },


  {
    "name": "Team Leader",
    "code": "TMLD",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: true },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: true },
      { isAllowToSeeWeeklyReport: true },
      { isAllowToApproveApplication: true },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Office Manager",
    "code": "OFCMNG",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },
  {
    "name": "Senior Inspector",
    "code": "SINSP",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },

  {
    "name": "Director Inspector",
    "code": "DINSP",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: false },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: false },
      { isAllowToSeeWeeklyReport: false },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },




  {
    "name": "Director",
    "code": "DRCT",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },
      { isAllowToSeeCertificate: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: true },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: true },
      { isAllowToSeeNonObjectionReport: true },
      { isAllowToSeeWeeklyReport: true },
      { isAllowToApproveApplication: true },
      { isAllowToLockApplication: true },
      { isAllowToAssignApplication: true },
    ]
  },


  {
    "name": "OSC Manager",
    "code": "OSCM",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },
      { isAllowToSeeCertificate: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: true },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: true },
      { isAllowToSeeWeeklyReport: true },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  },

  {
    "name": "Kubaka Manager ",
    "code": "KUBKM",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeMyBox: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },
      { isAllowToSeeCertificate: true },

      { isAllowToSeeUserReport: false },
      { isAllowToSeeGeneralApplicationReport: true },
      { isAllowToSeeFilteredApplicationReport: false },
      { isAllowToSeeDashboardReport: false },
      { isAllowToSeeChatBoardReport: false },
      { isAllowToSeeNonObjectionReport: true },
      { isAllowToSeeWeeklyReport: true },
      { isAllowToApproveApplication: false },
      { isAllowToLockApplication: false },
      { isAllowToAssignApplication: false },
    ]
  }


]














@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private appConfig: AppConfig,
    private router: Router
  ) { }

  getSession() {
    return JSON.parse(localStorage.getItem(this.appConfig.JWT_Token) as any);
  }


  getToken() {
    return localStorage.getItem(this.appConfig.JWT_Token) as any;
  }


  getTokenData() {
    return (jwtDecode(this.getSession().data.token.access_token) as any);
  }



  logout() {
    localStorage.removeItem(this.appConfig.JWT_Token);
    localStorage.removeItem(this.appConfig.UPI_NEW_INFO);
    localStorage.removeItem(this.appConfig.Session_User);
    localStorage.removeItem(this.appConfig.PERMI_ACCESS);
    localStorage.removeItem('application');
    this.router.navigate(['/']);
  }

  getPermission() {
    // return JSON.parse(localStorage.getItem(this.appConfig.Session_User) as any);
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from '../../services/util.service';

@Component({
  selector: 'app-input-file-upload',
  templateUrl: './input-file-upload.component.html',
  styleUrls: ['./input-file-upload.component.scss']
})
export class InputFileUploadComponent {
  currentUser: any;
  fileData: any = {};
  @Output() fileUpload = new EventEmitter();
  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

  }



  getFileDetails(e: any) {
    this.fileData.fileName = e.target.files[0].name;
    this.fileData.fileNameDisplay = e.target.files[0].name;
    this.handleFileSelected(e);
    if (this.fileData.fileType !== 'excel' && this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
      this.fileData.myFiles = [];
      for (var i = 0; i < e.target.files.length; i++) {
        this.fileData.myFiles.push(e.target.files[i]);
      }
      if (this.fileData.size > 10) {
        this.utilService.showNotification(
          NOTIFICATION_COLOR.error,
          "Error: " + 'File will not be saved because it is too big, try 50MB of size',
          "bottom",
          "center"
        );
      } else {
        this.fileData.file = e.target.files;
        this.fileUpload.emit(this.fileData)
      }
    } else {
      this.utilService.showNotification(
        NOTIFICATION_COLOR.error,
        "Error: " + 'PDF File allowed only',
        "bottom",
        "center"
      );
    }
  }



  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }

}

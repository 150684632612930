import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationService } from 'src/app/application/services/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-plot-on-map',
  templateUrl: './view-plot-on-map.component.html',
  styleUrls: ['./view-plot-on-map.component.scss']
})
export class ViewPlotOnMapComponent {

  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  applicationDetail: any = {};

  mapOptions: google.maps.MapOptions = {
    center: { lat: 38.9987208, lng: -77.2538699 },
    zoom: 14
  }
  marker = {
    position: { lat: 38.9987208, lng: -77.2538699 },
  }


  constructor(
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.applicationDetail = this.inputData.projects
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/upi/search?upi=' + this.inputData.upi)
      .subscribe(
        data => {
          this.marker = {
            position: { lat: 38.9987208, lng: -77.2538699 },
          }
        },
        error => { }
      )
  }


  close() {
    this.backToParent.emit(true);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VALUE } from 'src/app/core/value-enum/value-enum';
import { environment } from '../../../../../environments/environment';
import { NOTIFICATION_COLOR, UtilService } from '../../../../shared/services/util.service';
import { ApplicationService } from './../../../services/application.service';

@Component({
  selector: 'app-assign-to-engineer-form',
  templateUrl: './assign-to-engineer-form.component.html',
  styleUrls: ['./assign-to-engineer-form.component.scss']
})
export class AssignToEngineerFormComponent {
  @Input() inputData: any = {};
  @Output() backToParent = new EventEmitter();
  userForm!: UntypedFormGroup;
  submitted: boolean = false;
  currentUser: any = {};
  fileData: any = {};
  engineers: any[] = [];
  userTypes: any[] = [
    { id: '1', name: 'Engineer' },
    { id: '2', name: 'Architect' }
  ];

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private router: Router
  ) {
    this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer')
      .subscribe(
        data => {
          this.engineers = data;
        }
      )
    // this.currentUser = this.sessionService.getSession();
    // this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
  }



  focusOutFunction() {
    this.userForm.controls['percentageSpaceUse'].setValue(((this.userForm.value.buildUpArea / this.userForm.value.plotSize) * 100).toFixed(2));
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      userIdForAssignee: ["", [Validators.required]],
      userTypeId: ["", [Validators.required]],
      licenseNumber: ["", [Validators.required]],
      timeLineDays: ["", [Validators.required]],
      names: ["", [Validators.required]],
      projectId: [this.inputData.projectId, [Validators.required]],
      userCategoryId: ["", [Validators.required]],
      projectStatusId: [""],
      message: [""]
    });
  }



  submit() {

    // let replacedString = '';
    // if (this.userForm.value.licenseNumber.includes("/")) {
    //   let replacedString = this.userForm.value.licenseNumber.replace(/\//g, "%2F");
    // }
    if (this.userForm.value.names) {
      this.submitted = true;
      let code = this.userForm.value.userCategoryId === '1' ? 'ENG' : 'ARC'
      this.applicationService.findAllWithPath(environment.authUrl + 'user-management/userType/code/search?search=' + code)
        .subscribe(
          data => {
            this.userForm.controls['userTypeId'].setValue(data.items[0].id);
            this.applicationService.findAllWithPath(environment.applicationUrl + 'application/projectStatus/code/search?search=' + VALUE.PROJECT_ASSIGNED_CODE)
              .subscribe(
                projectStatusdata => {
                  this.userForm.controls['projectStatusId'].setValue(projectStatusdata.items[0].id);
                  this.confirmAssign();
                }
              )
          }
        )
    } else {
    }



  }

  confirmAssign() {
    this.applicationService.saveAssetWithPath(this.userForm.value, environment.applicationUrl + 'application/assignee')
      .subscribe(
        data => {
          this.utilService.showNotification(NOTIFICATION_COLOR.success, "Application assigned successfully", "bottom", "center");
          // this.router.navigate(['/account/all-applications/lists']);
          this.router.navigate(['/account/application/applications']);
          this.submitted = false;
        }, error => {
          this.submitted = false;
        }
      )
  }


  checkEngineer() {
    this.cleanDuringType()
    this.userForm.controls['message'].setValue('');
    if (this.userForm.value.userCategoryId === '1') {



      let replacedString = this.userForm.value.licenseNumber.replace(/\//g, "%2F");
      if (replacedString) {




        // this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Engineer/search/{licenceIdEng}?licenceIdEng=' + replacedString)
        this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Engineer/search/{licenceIdEng}?licenceIdEng=' + replacedString)
          .subscribe(
            data => {
              if (data) {
                this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer/{registrationNumber}?registrationNumber=' + replacedString)
                  .subscribe(
                    engData => {
                      if (engData) {
                        this.userForm.controls['names'].setValue(data?.firstName + ' ' + data?.lastName);
                        this.userForm.controls['userIdForAssignee'].setValue(data.id);
                        this.submitted = false;
                      } else {
                        this.submitted = false;
                        this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Engineer not found', 'top', 'right');
                      }
                    },
                  )
              } else {
                this.submitted = false;
                this.userForm.controls['message'].setValue('No data found for this licence number');
              }
            }, error => {
              this.submitted = false;
            }
          )









        // this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer/' + replacedString)
        //   .subscribe(
        //     engData => {
        //       if (engData) {
        //         // this.isVerified();
        //         // this.accountTypeForm.controls['engineerData'].setValue(data);

        //         this.applicationService.findAllWithPath(environment.authUrl + "user-management/user/Engineer/search/" + replacedString)
        //           // this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Engineer/search?search=' + replacedString)
        //           .subscribe(
        //             data => {
        //               if (data) {
        //                 this.userForm.controls['names'].setValue(data?.firstName + ' ' + data?.lastName);
        //                 this.userForm.controls['userIdForAssignee'].setValue(data.id);
        //                 this.submitted = false;
        //               } else {
        //                 this.submitted = false;
        //                 this.userForm.controls['message'].setValue('No data found for this licence number');
        //               }
        //               // if (data && data.items.length > 0) {
        //               //   this.userForm.controls['names'].setValue(data.items[0]?.firstName + ' ' + data.items[0]?.lastName);
        //               //   this.userForm.controls['userIdForAssignee'].setValue(data.items[0].id);
        //               //   this.submitted = false;
        //               // } else {
        //               //   this.submitted = false;
        //               //   this.userForm.controls['message'].setValue('No data found for this licence number');
        //               // }
        //               // this.confirmAssign();
        //             }, error => {
        //               this.submitted = false;
        //             }
        //           )
        //       } else {
        //         this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Engineer not found', 'top', 'right');
        //       }
        //       // this.registerDetails();
        //     },
        //   )





      }



    }

    if (this.userForm.value.userCategoryId === '2') {


      this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Architect/search/' + this.userForm.value.licenseNumber)
        .subscribe(
          data => {
            if (data) {
              // this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer')
              //   .subscribe(
              //     engData => {
                    let findArchtc = this.engineers.find((x: any) => x.value?.registrationindex === this.userForm.value.licenseNumber)?.value;
                    if (findArchtc) {
                      this.userForm.controls['names'].setValue(data.firstName + ' ' + data.lastName);
                      this.userForm.controls['userIdForAssignee'].setValue(data.id);
                      this.submitted = false;
                    } else {
                      this.submitted = false;
                      this.utilService.showNotification(NOTIFICATION_COLOR.error, 'The certificate is invalid. Contact your association for more information', 'top', 'right');
                    }
                  // },
                // )
            } else {
              this.userForm.controls['message'].setValue('No data found for this licence number');
              this.submitted = false;
            }
          }, error => {
            this.submitted = false;
          }
        )





      // this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Architect/search?search=' + this.userForm.value.licenseNumber)


      // this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer')
      //   .subscribe(
      //     data => {
      //       let findArchtc = this.engineers.find((x: any) => x.value.registrationindex === this.userForm.value.licenseNumber).value;
      //       if (findArchtc) {
      //         this.applicationService.findAllWithPath(environment.authUrl + 'user-management/user/Architect/search/' + this.userForm.value.licenseNumber)
      //           .subscribe(
      //             data => {
      //               if (data) {
      //                 this.userForm.controls['names'].setValue(data.firstName + ' ' + data.lastName);
      //                 this.userForm.controls['userIdForAssignee'].setValue(data.id);
      //                 this.submitted = false;
      //               }
      //               this.userForm.controls['message'].setValue('No data found for this licence number');
      //               // if (data && data.items.length > 0) {
      //               //   this.userForm.controls['names'].setValue(data.items[0].firstName + ' ' + data.items[0].lastName);
      //               //   this.userForm.controls['userIdForAssignee'].setValue(data.items[0].id);
      //               //   this.submitted = false;
      //               // }
      //               // this.userForm.controls['message'].setValue('No data found for this licence number');
      //               this.submitted = false;
      //             }, error => {
      //               this.submitted = false;
      //             }
      //           )
      //         // this.isVerified();
      //         // this.accountTypeForm.controls['archictData'].setValue(findArchtc);
      //         // this.registerDetails();
      //       } else {
      //         // this.registerDetails();
      //         this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Architect not found', 'top', 'right');

      //       }
      //     },
      //   )


    }
  }


  cleanDuringType() {
    this.userForm.controls['names'].setValue('');

  }

  changeDetectionValue() {
    this.userForm.controls['licenseNumber'].setValue('')
    this.cleanDuringType();
  }
}




<div class="ck-container" *ngIf="info !== '2'">
    <div class="ck-header">
        <div class="kbk-x-c sp-sm kbk-wrap kbk-aend" *ngIf="info !== '2'">
            <div>
                <label>{{ "zoningCheck.search" | translate }}</label>
                <input type="text" class="flg" name="upiFinder" placeholder="1/02/11/01/3024" [(ngModel)]="upiFinder"
                    (input)="onInput($event)" maxlength="23">
                <!-- <input type="text" class="flg" name="upiFinder" placeholder="1/02/11/01/3024" 
                    [(ngModel)]="upiFinder"
                    
                    [textMask]="{mask: mask}"
                        > -->
            </div>
            <button type="button" *ngIf="!submitted" class="kbk-btn kbk-btn-main kbk-btn-lg" (click)="searchUpi()">{{
                "zoningCheck.searchButton" | translate }}</button>
            <button type="button" *ngIf="submitted" class="kbk-btn kbk-btn-main kbk-btn-lg">Check requirement in
                progress...</button>
        </div>
        <!-- error message -->
        <div *ngIf="!upiResult.upi && checkingData.isSearching" class="alert alert-success" role="alert">{{
            checkingData.label}}</div>
    </div>
    <hr>
    <div class="ck-body">
        <div class="alert alert-danger" role="alert" *ngIf="rraData.isError"> Could you kindly ensure that your taxes
            are paid? This plot has some unpaid taxes.</div>
    </div>
    <div class="ck-body" *ngIf="upiResult.upi">
        <div class="track-info">
            <div class="track-profile">
                <img src="assets/imgs/profile1.svg" alt="" />
            </div>
            <div class="track-dtail">
                <!-- <span *ngIf="info === '1' && upiResult.representative"
                    class="track-user">
                    {{upiResult.representative.surname}}
                    {{upiResult.representative.foreNames}}
                </span> -->
                <span *ngIf="info === '1' && upiResult.owners.length > 0" class="track-user">
                    {{upiResult.owners[0].fullName}} </span>
                <!-- <span *ngIf="info === '1' && !upiResult.representative"
                    class="track-user">{{upiResult.owners[0].fullName}} </span> -->
                <span *ngIf="info === '1' && !upiResult.representative"
                    class="track-user">{{upiResult.owners[0].fullName}} </span>
                <span class="track-usersub">{{upiResult.upi}}</span>
                <span *ngIf="info === '2'" class="track-usersub"> Is Under Restriction :{{upiResult.isUnderRestriction ?
                    'Yes' : 'No'}}</span>
                <span *ngIf="info === '2'" class="track-usersub"> Is Under Mortgage :{{upiResult.isUnderMortgage ? 'Yes'
                    : 'No'}}</span>
            </div>
        </div>
        <div class="mt-md">
            <div class="kbk-x">
                <div class="rnd-crd rnd-loc">
                    <h3 class="loc">Location</h3>
                    <div class="kbk-x sp-md">
                        <div class="rnd-crd_info">
                            <label>District</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.district.districtName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Sector</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.sector.sectorName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Cell</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.cell.cellName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Village</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.village.villageName}}</span>
                        </div>
                    </div>
                    <!-- <div class="kbk-x sp-md">
                        <div class="rnd-crd_info">
                            <label>District</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.district.districtName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Sector</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.sector.sectorName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Cell</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.cell.cellName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Village</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.village.villageName}}</span>
                        </div>
                    </div> -->
                </div>
                <div class="rnd-crd rnd-use">
                    <h3 class="usz">Land use</h3>
                    <div class="rnd-crd_info">
                        <label>Registered use</label>
                        <span class="rnd-crd_title">{{upiResult.landUseNameEnglish}}</span>
                    </div>
                </div>
            </div>
            <div class="rnd-crd rnd-zon">
                <h3 class="zng">Zoning</h3>
                <div class="kbk-x-s sp-md">
                    <div class="rnd-crd_info" *ngFor="let zn of upiResult.plannedLandUses">
                        <label>{{zn.header}}</label>
                        <div class="rnd-crd_zn">
                            <span class="rnd-crd_hd">{{zn.landUse}}</span>
                            <span class="rnd-crd_title">{{zn.use}} (Area: {{zn.area}} Sqm)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="upiResult.hasRoad" class="trackrepo trackrepo-pending">
            <p> There is a <strong>Road</strong> that passes through your plot. </p>
        </div>
    </div>
    <div class="ck-note" *ngIf="upiResult?.plannedLandUses && upiResult?.plannedLandUses?.length > 0">
        <h3>Please select what you want to build according to the master plan </h3>
        <ul class="accordion-list accord-tip">
            <li class="accordion-item" *ngFor="let up of upiResult.plannedLandUses">
                <label class="accordion-btn" (click)="searchZoneDetails(up)">
                    <input type="checkbox" style="display: none" /> {{up.landUse}} {{up.use}} <span
                        class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Permitted">
                                    <span *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}} <a *ngIf="info === '1'"
                                            class="cl-link" (click)="applyOn(ptu, 'Permitted use')">Apply</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Prohibited"><span
                                        *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}}</span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Conditional_uses"><span
                                        *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}} <a *ngIf="info === '1'"
                                            class="cl-link" (click)="applyOn(ptu, 'Conditional use')">Apply</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <!-- <div class="ck-note">
        <h3>Please select what you want to build according to the master plan</h3>
        <ul class="accordion-list accord-tip">
            <li class="accordion-item">
                <label class="accordion-btn">
                    <input type="checkbox" style="display: none" /> A1 Agriculture zone <span class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of permittedUse"><span
                                        class="bul-list-info">{{ptu.name}} <a *ngIf="info === '1'" class="cl-link"
                                            (click)="applyOn(ptu)">Apply</a></span></li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of prohibitedUse"><span
                                        class="bul-list-info">{{ptu.name}}</span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of conditionalUse"><span
                                        class="bul-list-info">{{ptu.name}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="accordion-item">
                <label class="accordion-btn">
                    <input type="checkbox" style="display: none" /> T Transportation zone <span class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of permittedUse"><span
                                        class="bul-list-info">{{ptu.name}} <a *ngIf="info === '1'" class="cl-link"
                                            (click)="applyOn(ptu)">Apply</a></span></li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item"><span class="bul-list-info">Industrial uses</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Residential uses</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Commercial uses </span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Public facilities</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">All type of agriculture in water
                                        bodies bufferzones except agroforestry </span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item"><span class="bul-list-info">Agriculture</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Temporary Farm Store</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Feeder roads Infrastructure</span>
                                </li>
                                <li class="bul-list-item"><span class="bul-list-info">Drying facilities,Store,and
                                        washing station,agro-selling Points</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">All types of livestock
                                        farming</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div> -->
</div>
<div class="ck-container" *ngIf="info === '2'">
    <hr>
    <div class="ck-body">
        <div class="alert alert-danger" role="alert" *ngIf="rraData.isError"> Could you kindly ensure that your taxes
            are paid? This plot has some unpaid taxes.</div>
    </div>
    <div class="ck-body" *ngIf="upiResult.upi">
        <div class="track-info">
            <div class="track-profile">
                <img src="assets/imgs/profile1.svg" alt="" />
            </div>
            <div class="track-dtail">
                <span>Representative</span>
                <span *ngIf="upiResult?.representative" class="track-user"> {{upiResult.representative.surname}}
                    {{upiResult.representative.foreNames}} </span>
                <span *ngIf="info === '1' && upiResult.owners.length > 0" class="track-user">
                    {{upiResult.owners[0].fullName}} </span>
                <!-- <span *ngIf="info === '1' && !upiResult.representative"
                    class="track-user">{{upiResult.owners[0].fullName}} </span> -->
                <span *ngIf="info === '1' && !upiResult.representative"
                    class="track-user">{{upiResult.owners[0].fullName}} </span>
                <span class="track-usersub">{{upiResult.upi}}</span>
                <span *ngIf="info === '2'" class="track-usersub"> Does plot under Restriction : {{upiResult.isUnderRestriction ?
                    'Yes' : 'No'}}</span>
                <span *ngIf="info === '2'" class="track-usersub"> Does plot under Mortgage : {{upiResult.isUnderMortgage ? 'Yes'
                    : 'No'}}</span>
            </div>
        </div>
        <div class="app-dash">
            <div class="dash-flex">
                <div class="app-l">
                    <span>Owners</span>
                    <div class="progress-steps">
                        <ul class="step-list">
                            <li class="step-list_item" *ngFor="let ow of upiResult.owners">
                                <div class="step-list_titl step-ln">
                                    <h5>Names: <span>{{ow.fullName}}</span></h5>
                                    <h5>National ID: <span>{{ow.idNo}}</span></h5>
                                    <h5>Marital status: <span>{{ow.maritalStatus}}</span></h5>
                                    <h5>Gender: <span>{{ow.gender === 'M'? 'Male' : 'Female'}}</span></h5>
                                </div>
                                <!-- <div class="step-list_dscb">
                            <p>Comment: {{dt.comment}}</p>
                        </div> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-md">
            <div class="kbk-x">
                <div class="rnd-crd rnd-loc">
                    <h3 class="loc">Location</h3>
                    <div class="kbk-x sp-md">
                        <div class="rnd-crd_info">
                            <label>District</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.district.districtName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Sector</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.sector.sectorName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Cell</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.cell.cellName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Village</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.village.villageName}}</span>
                        </div>
                    </div>
                    <!-- <div class="kbk-x sp-md">
                        <div class="rnd-crd_info">
                            <label>District</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.district.districtName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Sector</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.sector.sectorName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Cell</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.cell.cellName}}</span>
                        </div>
                        <div class="rnd-crd_info">
                            <label>Village</label>
                            <span class="rnd-crd_title">{{upiResult.parcelLocation.village.villageName}}</span>
                        </div>
                    </div> -->
                </div>
                <div class="rnd-crd rnd-use">
                    <h3 class="usz">Land use</h3>
                    <div class="rnd-crd_info">
                        <label>Registered use</label>
                        <span class="rnd-crd_title">{{upiResult.landUseNameEnglish}}</span>
                    </div>
                </div>
            </div>
            <div class="rnd-crd rnd-zon">
                <h3 class="zng">Zoning</h3>
                <div class="kbk-x-s sp-md">
                    <div class="rnd-crd_info" *ngFor="let zn of upiResult.plannedLandUses">
                        <label>{{zn.header}}</label>
                        <div class="rnd-crd_zn">
                            <span class="rnd-crd_hd">{{zn.landUse}}</span>
                            <span class="rnd-crd_title">{{zn.use}} (Area: {{zn.area}} Sqm)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="upiResult.hasRoad" class="trackrepo trackrepo-pending">
            <p> There is a <strong>Road</strong> that passes through your plot. </p>
        </div>
    </div>
    <div class="ck-note" *ngIf="upiResult?.plannedLandUses && upiResult?.plannedLandUses?.length > 0">
        <h3>Please select what you want to build according to the master plan </h3>
        <ul class="accordion-list accord-tip">
            <li class="accordion-item" *ngFor="let up of upiResult.plannedLandUses">
                <label class="accordion-btn" (click)="searchZoneDetails(up)">
                    <input type="checkbox" style="display: none" /> {{up.landUse}} {{up.use}} <span
                        class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Permitted">
                                    <span *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}} <a *ngIf="info === '1'"
                                            class="cl-link" (click)="applyOn(ptu, 'Permitted use')">Apply</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Prohibited"><span
                                        *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}}</span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of regulations.Conditional_uses"><span
                                        *ngIf="up.landUse === ptu.zone.code"
                                        class="bul-list-info">{{ptu.regulation.name}} <a *ngIf="info === '1'"
                                            class="cl-link" (click)="applyOn(ptu, 'Conditional use')">Apply</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <!-- <div class="ck-note">
        <h3>Please select what you want to build according to the master plan</h3>
        <ul class="accordion-list accord-tip">
            <li class="accordion-item">
                <label class="accordion-btn">
                    <input type="checkbox" style="display: none" /> A1 Agriculture zone <span class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of permittedUse"><span
                                        class="bul-list-info">{{ptu.name}} <a *ngIf="info === '1'" class="cl-link"
                                            (click)="applyOn(ptu)">Apply</a></span></li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of prohibitedUse"><span
                                        class="bul-list-info">{{ptu.name}}</span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of conditionalUse"><span
                                        class="bul-list-info">{{ptu.name}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li class="accordion-item">
                <label class="accordion-btn">
                    <input type="checkbox" style="display: none" /> T Transportation zone <span class="more-btn"></span>
                </label>
                <div class="accordion-content">
                    <div class="kbk-x kbk-wrap-3">
                        <div class="bul bul-perm">
                            <h4 class="bul-hdr">Permitted use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item" *ngFor="let ptu of permittedUse"><span
                                        class="bul-list-info">{{ptu.name}} <a *ngIf="info === '1'" class="cl-link"
                                            (click)="applyOn(ptu)">Apply</a></span></li>
                            </ul>
                        </div>
                        <div class="bul bul-proh">
                            <h4 class="bul-hdr">Prohibited use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item"><span class="bul-list-info">Industrial uses</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Residential uses</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Commercial uses </span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Public facilities</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">All type of agriculture in water
                                        bodies bufferzones except agroforestry </span></li>
                            </ul>
                        </div>
                        <div class="bul bul-cond">
                            <h4 class="bul-hdr">Conditional use</h4>
                            <ul class="bul-list">
                                <li class="bul-list-item"><span class="bul-list-info">Agriculture</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Temporary Farm Store</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">Feeder roads Infrastructure</span>
                                </li>
                                <li class="bul-list-item"><span class="bul-list-info">Drying facilities,Store,and
                                        washing station,agro-selling Points</span></li>
                                <li class="bul-list-item"><span class="bul-list-info">All types of livestock
                                        farming</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div> -->
</div>
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-additional-upload-file',
  templateUrl: './additional-upload-file.component.html',
  styleUrls: ['./additional-upload-file.component.scss']
})
export class AdditionalUploadFileComponent {
  @Input() inputData: any = {};
  pageController: any = {};
  userForm!: UntypedFormGroup;
  fileData: any = {};
  outputData: any = {};
  currentUser: any = {};


  constructor(
    private utilService: UtilService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private sessionService: SessionService,
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

  }


  get form() {
    return this.userForm.controls;
  }

  ngOnInit() {
    console.log(this.inputData);
    this.userForm = this.formBuilder.group({
      applicationId: [""],
      userId: [this.currentUser.userId],
      additionalDescription: [""],
      uploadType: ["2"],
    });

    this.loadDocuments();
  }


  loadDocuments() {
    this.applicationService.findAllWithPath(environment.documentUrl + "DocMgt/documents/uploadAdditional/" + this.inputData.id)
      .subscribe(
        data => {
          this.pageController.documents = data;
        },
        error => { }
      )
  }


  // getFileDetails(e: any, docInfo: any) {
  getFileDetails(e: any) {
    let docInfo: any = {}
    const fileInput: any = e.target as HTMLInputElement;


    this.fileData.fileName = e.target?.files[0]?.name;
    this.fileData.fileNameDisplay = e.target?.files[0]?.name;
    this.userForm.controls['additionalDescription'].setValue(this.fileData.fileNameDisplay);
    this.handleFileSelected(e);
    if (this.fileData.fileType !== 'excel' && this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
      this.fileData.myFiles = [];
      for (var i = 0; i < e.target.files.length; i++) {
        this.fileData.myFiles.push(e.target.files[i]);
      }
      if (this.fileData.size > this.utilService.fileSize) {
        this.utilService.showNotification(
          NOTIFICATION_COLOR.error,
          "Error: " + 'File will not be saved because it is too big, try 20MB of size',
          "bottom",
          "center"
        );
        fileInput.value = ''; // Reset input
        return;
      } else {
        this.fileData.file = e.target.files;
        this.onSubmit();
      }
    } else {
      this.utilService.showNotification(
        NOTIFICATION_COLOR.error,
        "Error: " + 'PDF File allowed only',
        "bottom",
        "center"
      );
      // Reset input after error
      setTimeout(() => {
        fileInput.value = '';
      }, 0);
    }

  }


  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }


  onSubmit() {
    let formData = new FormData();
    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file?.name
      formData.append('file', file, file?.name);
    }
    formData.append('additionalDescription', this.userForm.value.additionalDescription);
    formData.append('applicationId', this.inputData.id);
    formData.append('uploadType', this.userForm.value.uploadType);
    this.pageController.fileSubmit = true;
    this.applicationService.saveAssetWithPathFormData(formData, environment.documentUrl + 'DocMgt/uploadAdditional')
      .subscribe(
        data => {
          this.pageController.fileSubmit = false;
          this.pageController.submitted = false;
          this.fileData.fileNameDisplay = '';
          this.loadDocuments();
        }, (error) => {
          this.pageController.submitted = false;
          this.pageController.fileSubmit = false;
          this.fileData.fileNameDisplay = '';
          this.utilService.showFewMinutesNotification(NOTIFICATION_COLOR.error, error, "bottom", "center");
        }
      )
  }




  viewDocument(component: any, doc: any, sizeParams: any) {
    console.log(doc)
    this.pageController.loading = true;
    this.applicationService.findAllWithPath(environment.documentUrl + 'DocMgt/' + doc?.fileName + '/base64')
      .subscribe(
        data => {
          this.pageController.loading = false;
          if (this.utilService.isExcelFile(data.base64Data)) {
            this.utilService.downloadExcel(data.base64Data);
          } else {
            this.outputData = data;
            this.outputData.currentId = doc.id;
            this.modalService.open(component, { size: sizeParams, centered: true });
          }
        }, error => {
          this.pageController.loading = false;
        }
      )

  }



  deleteFile() {
    this.pageController.isDeleting = true;
    this.applicationService.deleteWithPathNoId(environment.documentUrl + "DocMgt/" + this.pageController?.deleteData?.id)
      .subscribe(
        data => {
          this.modalService.dismissAll();
          console.log("delete is initiated")

          this.pageController.isDeleting = false;
          this.utilService.showNotification(NOTIFICATION_COLOR.success, "Document removed successfully", "bottom", "center");
          this.loadDocuments();
        },
        error => { this.pageController.isDeleting = false; }
      )
  }


  openDeleteModal(content: any, sizeParams: any, value: any) {
    console.log(value);
    this.pageController.deleteData = value;
    this.modalService.open(content, { size: sizeParams, centered: true });
  }
}

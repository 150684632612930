import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InputFileUploadComponent } from './shared/components/input-file-upload/input-file-upload.component';
import { VerifyCertificateComponent } from './auth-pages/components/external-certificate/verify-certificate/verify-certificate.component';
import { AuthGuard } from './guards/authentication.guard';

const routes: Routes = [
  {
    path: '', pathMatch: "full", redirectTo: "auth"
  },
  {
    path: '', loadChildren: 
    () => import('../app/auth-pages/modules/auth-page.module').then(m => m.AuthPageModule), canActivate: [AuthGuard]
    
  },

  // canActivate: [AuthGuard],
  { path: 'account', loadChildren: () => import('../app/account-layout/modules/account.module').then(m => m.AccountModule)
    ,canActivate: [AuthGuard]
   },
  { path: 'grid', loadChildren: () => import('../app/ag-grid-management/modules/ag-grid/ag-grid.module').then(m => m.AgGridModule) },
  { path: 'file-upload', component: InputFileUploadComponent },
  { path: 'verify-certificate/:id', component: VerifyCertificateComponent },
  { path: 'payment', loadChildren: () => import('../app/payment/modules/payment.module').then(m => m.PaymentModule) },
  { path: 'verification', loadChildren: () => import('../app/auth-pages/components/external-certificate/modules/external-page.module').then(m => m.ExternalPageModule) },
  { path: '**', redirectTo: '/' }, // Redirect unknown routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

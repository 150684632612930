import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from 'src/app/auth-pages/services/auth.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { interval, Subscription, takeWhile } from 'rxjs';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent {
  messages: any[] = ['How can we help with Kubaka?'];
  userMessage: string = '';
  currentUser: any;
  userForm!: UntypedFormGroup;
  // private subscription!: Subscription;
  issueCategories: any[] = [];
  agencies: any[] = [];
  isOpenConversation: boolean = false;
  private subscription!: Subscription;


  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilService
  ) {
    if (this.sessionService.getSession()) {
      this.currentUser = this.sessionService.getSession();
      this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
      this.startBackgroundFetch()
    }


    this.authService.findAllWithPath(environment.chatUrl + 'issueCategory')
      .subscribe(
        data => {
          this.issueCategories = data;
        }
      )

    this.authService.findAllWithPath(environment.authUrl + APIURLPATH.AGENCIES)
      .subscribe(
        data => {
          this.agencies = data;
        }
      )
  }





  findThePhoneConversation() {
    if (this.userForm.value.phoneNumber) {
      this.authService.findAllWithPath(environment.chatUrl + 'chatBoard/phone/' + this.userForm.value.phoneNumber)
        .subscribe(
          data => {
            this.messages = data;
            // this.isOpenConversation = true;

          }, error => {
            // this.onSubmit();
          }
        )
    }

  }

  startBackgroundFetch(): void {
  //  setInterval(() => {
  //     // this.loadMessages();
  //     this.findThePhoneConversation();
  //   }, 5000); // Fetch every 5 seconds
    this.subscription = interval(5000).subscribe(() => {
      // if (!this.isDestroyed) {
        this.findThePhoneConversation();
      // }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // throw new Error('Method not implemented.');


    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      senderNames: [""],
      agencyId: [''],
      content: [''],
      issueCategoryId: [''],
      phoneNumber: [''],
      isReply: ['0', Validators.required],
    });



    if (this.currentUser) {
      this.userForm.controls['senderNames'].setValue(this.currentUser.data.user.firstName + ' ' + this.currentUser.data.user.lastName);
      this.userForm.controls['phoneNumber'].setValue(this.currentUser.data.user.phoneNumber);
      this.findThePhoneConversation();
    }

    if (this.currentUser && this.currentUser.data && this.currentUser.data.user && this.currentUser.data.user.agency) {
      this.userForm.controls['agencyId'].setValue(this.currentUser.data.user.agency.id);

    }

  }

  sendMessage() {
    // if (this.userMessage.trim()) {
    //   this.messages.push(this.userMessage);
    //   this.userMessage = ''; // Clear the input
    // }
    this.onSubmit();
  }


  onSubmit() {

    this.userForm.controls['content'].setValue(this.userMessage);
    // if (!this.userMessage && this.messages.length === 0) {

    // } else 

    if (this.userForm.value.phoneNumber.length < 10) {
      this.utilsService.showNotification(NOTIFICATION_COLOR.error, "Phone number should contains 10 digits", "bottom", "center");

    } else if (this.userForm.value.agencyId && this.userForm.value.issueCategoryId && this.userForm.value.phoneNumber) {
      if (this.userForm.value.phoneNumber && this.messages.length > 0) {
        // this.userForm.controls['content'].setValue('Hello,');
        this.saveMessage();
      } else if (this.userForm.value.phoneNumber && (this.messages.length === 0 || this.messages.length > 0)
      ) {
        this.saveMessage();
      }
    } else {
      this.utilsService.showNotification(NOTIFICATION_COLOR.error, "Please fill phone number, category and agency to proceed", "bottom", "center");
    }




  }



  saveMessage() {
    this.authService.saveAssetWithPath(this.userForm.value, environment.chatUrl + 'chatBoard')
      .subscribe(
        data => {
          this.userMessage = '';
          this.findThePhoneConversation();
          // this.startBackgroundFetch();
        }
      )
  }
}

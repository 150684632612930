import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  GridApi, // Import GridApi
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-application-approval-report',
  templateUrl: './application-approval-report.component.html',
  styleUrls: ['./application-approval-report.component.scss']
})
export class ApplicationApprovalReportComponent {
  currentUser: any = {};
  users: any[] = [];
  // Variables to store the grid API and column API
  private gridApi!: GridApi;
  isUserShow: boolean = false;
  userId: any = '';
  rowData: any[] = [];
  colDefs: ColDef[] = [
    { field: 'applications.applicationName', headerName: 'Application Name', width: 150, checkboxSelection: true },
    { field: 'applications.buildUpArea', headerName: 'Build Up Area', width: 130 },
    { field: 'applications.numberOfFloor', headerName: 'Number Of Floor', width: 130 },
    { field: 'applications.grossFloorArea', headerName: 'Gross Floor Area', width: 130 },
    { field: 'applications.priceOfDwellingUnitRwf', headerName: 'Price Of Dwelling Unit Rwf', width: 130 },
    { field: 'applications.permitTypeCode', headerName: 'Permit Type Code', width: 130 },
    { field: 'applications.capacityInformation', headerName: 'Capacity Information', width: 130 },
    { field: 'applications.electricityConsumption', headerName: 'Electricity Consumption', width: 130 },
    { field: 'applications.waterConsumption', headerName: 'Water Consumption', width: 130 },
    { field: 'applications.ProjectCostInUSD', headerName: 'Project Cost In USD', width: 130 },
    { field: 'applications.ProjectCostInRwf', headerName: 'Project Cost In Rw', width: 130 },
    { field: 'approvalStatus.name', headerName: 'Status', width: 130 },
    { field: 'comment', headerName: 'Application Name', width: 130 },
  ];

  defaultColDef: ColDef = {
    filter: true,
    editable: true,
  };



  constructor(private http: HttpClient,
    public reportService: ApplicationService
    , private sessionService: SessionService,
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

    this.reportService.findAllWithPath(environment.applicationUrl + 'approval/applicationApproval')
      .subscribe(
        data => { this.users = data }
      )
  }


  chooseId(event: any) {
    let user = this.users.find((x: any) => x.id === event.id);
    this.reportService.searchTerm = user.firstName + ' ' + user.lastName;
    this.userId = user.id;
    this.isUserShow = false;
    this.onSearchTermChange('');
  }

  open() {
    this.isUserShow = true;
  }

  onSearchTermChange(event: any) {

    if (this.reportService.filterByDate && this.reportService.filterEndDate &&
      this.reportService.searchTerm
    ) {
      let dataToSave = {
        "userId": this.userId,
        "startDate": this.reportService.filterByDate,
        "endDate": this.reportService.filterEndDate
      }
      this.reportService.saveAssetWithPath(dataToSave, environment.applicationUrl + 'approval/applicationApproval/staffReport')
        .subscribe(
          data => { this.rowData = data }
        )
    }

  }

  onSearchByDateChange(event: any) {

    if (this.reportService.filterByDate && this.reportService.filterEndDate &&
      this.reportService.searchTerm
    ) {
      let dataToSave = {
        "userId": this.userId,
        "startDate": this.reportService.filterByDate,
        "endDate": this.reportService.filterEndDate
      }
      this.reportService.saveAssetWithPath(dataToSave, environment.applicationUrl + 'approval/applicationApproval/staffReport')
        .subscribe(
          data => { this.rowData = data }
        )
    }
  }
  onGridReady(params: GridReadyEvent) {
    // Save the grid API reference
    this.gridApi = params.api;

    this.http
      .get<any[]>(environment.authUrl + 'chatBoard')
      .subscribe((data) => (this.rowData = data));
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    console.log(`New Cell Value: ${event.value}`);
  }

  dateFormatter(params: ValueFormatterParams) {
    return new Date(params.value).toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  // Export to Excel using the grid API
  onBtnExport() {
    const params = {
      fileName: 'user-report.xlsx',
      onlySelected: true, // Export only selected rows
      columnKeys: ['firstName', 'lastName', 'email'], // Export specific columns
    };
    // Use the grid API to export data as Excel
    this.gridApi.exportDataAsExcel(params);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { FormattingService } from 'src/app/shared/services/formatting.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
// import * as crypto from 'crypto';

@Component({
  selector: 'app-upi-info',
  templateUrl: './upi-info.component.html',
  styleUrls: ['./upi-info.component.scss']
})
export class UpiInfoComponent {
  @Input() info: any;
  @Input() upiData: any;
  @Output() backToParent = new EventEmitter();
  submitted: boolean = false;
  upiResult: any = {};
  upiFinder: any = '';
  rraData: any = {};
  regulations: any = {};
  checkingData: any = {};
  currentUser: any = {};

  mask = [/[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private formattingService: FormattingService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private router: Router
  ) {
    // console.log(this.categorizeByUse(this.testData));


  }


  ngOnInit() {
    console.log(this.info)
    this.currentUser = this.sessionService.getSession();
    if (this.currentUser) {
      this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    }

    if (this.info === '2') {
      this.searchInALand();
    }
  }


  categorizeByUse(data: any[]) {
    const categorizedData: { [key: string]: any[] } = {};
    data.forEach((item) => {
      let use = item.use.name;
      use = use.replace(/\s+/g, '_');
      if (categorizedData[use]) {
        categorizedData[use].push(item);
      } else {
        categorizedData[use] = [item];
      }
    });
    return categorizedData;
  }


  searchZoneDetails(event: any) {
    this.applicationService.findAllWithPath('https://api.kubaka.gov.rw/land/zones/' + event.landUse + '/regulations')
      .subscribe(
        data => {
          this.regulations = this.categorizeByUse(data.data);
        }
      )
  }


  searchUpi() {
    this.checkingData.isSearching = true;
    this.rraData.isError = false;
    if (this.info === '0') {
      this.searchInALand();
    } else if (this.info === '1') {
      // this.checkIfUPIIsExistInOldSystem();
      this.checkRRA();
    } else if (this.info === '2') {
      this.searchInALand();
    }
  }


  // First check in RRA
  checkRRA() {
    this.submitted = true;
    this.checkingData.label = "Checking UPI in RRA";
    this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/rra?upi=' + this.upiFinder)
      .subscribe(
        data => {
          this.checkingData.label = "";
          if (data && data.message === 'Server Down') {

            this.checkIfUPIIsExistInOldSystem();
          } else if (data && data.ResponseObject && data.ResponseObject.StatusCode === 1) {
            this.rraData.isRRAVerified = true;
            this.checkIfUPIIsExistInOldSystem();
          } else {
            this.submitted = false;
            this.rraData.isError = true
            this.utilService.showNotification(NOTIFICATION_COLOR.error, "This plot has some unpaid taxes", "bottom", "center");
          }
        }, error => {
          this.checkingData.label = "";
          this.submitted = false;
          this.checkIfUPIIsExistInOldSystem();
        }
      )

  }

  checkIfUPIIsExistInOldSystem() {

    let splitValue = this.upiFinder.replace(/\//g, "%2F");
    this.submitted = true;
    this.checkingData.label = "Checking UPI in Old System";
    // this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/old-bpmis/' + splitValue)
    this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/old-bpmis?upi=' + splitValue)
      .subscribe(
        oldSystemExistingData => {
          this.checkingData.label = "";
          if (oldSystemExistingData && oldSystemExistingData.data.length > 0) {
            // Existing information from old system
            this.upiResult = oldSystemExistingData.data[0];
            this.submitted = false;
            this.applyOnUpiFromOldSystem();
          } else {
            // this.submitted = false;
            // check if upi already exists in BPMIS
            this.checkIfUPIIsExistInBPMIS();
          }
        },
        error => { this.checkingData.label = ""; this.submitted = false; }
      )
  }



  checkIfUPIIsExistInBPMIS() {
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/upi/search?search=' + this.upiFinder)
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/upi/search?search=' + this.upiFinder)
      .subscribe(
        existingData => {
          if (existingData && existingData.items.length > 0) {
            // Existing information
            this.submitted = false;
            this.upiResult.isFromOldSystem = false; // this means project is already exists in BPMIS
            if (existingData.items.length > 0) {
              // this.routeToOtherRelatedApplication(existingData.items[0].id);
              this.upiResult.bpmisProjectId = existingData.items[0].id;
              this.continueByCheckIfHasApplication();
            } else {
              this.utilService.showNotification(NOTIFICATION_COLOR.error, "Project already exists but identifier not found", "bottom", "center");
            }

          } else {
            // this.applicationService.findAllWithPath(environment.landAPI + this.upiFinder)
            //   .subscribe(
            //     data => {
            //       if (data.data.upi) {
            //         this.upiResult = data.data;
            //         this.upiResult.isFromOldSystem = false; // Means this data if from BPMIS System
            //         // Planned Land Use Format function used to fomart our land use in readable letter
            //         this.upiResult.plannedLandUses = this.formattingService.plannedLandUseFormat(this.upiResult.plannedLandUses);
            //         if (this.upiResult.plannedLandUses.find((x: any) => x.landUse === 'T')) {
            //           this.upiResult.hasRoad = true;
            //         } else {
            //           this.upiResult.hasRoad = false;
            //         }
            //         this.submitted = false;
            //       } else {
            //         this.submitted = false;
            //         this.utilService.showNotification(NOTIFICATION_COLOR.error, 'No data found for this UPI : ' + this.upiFinder, "bottom", "center");
            //       }
            //     }, error => { this.submitted = false; }
            //   )
            this.searchInALand();
          }
        },
        error => { this.submitted = false; }
      )
  }


  onInput(event: any) {
    let value = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (value.length > 1) {
      value = value.replace(/^(\d{1})(\d{0,2})/, '$1 / $2');
    }
    if (value.length > 6) {
      value = value.replace(/^(\d{1}) \/ (\d{2})(\d{0,2})/, '$1 / $2 / $3');
    }
    if (value.length > 10) {
      value = value.replace(/^(\d{1}) \/ (\d{2}) \/ (\d{2})(\d{0,2})/, '$1 / $2 / $3 / $4');
    }
    if (value.length > 14) {
      value = value.replace(/^(\d{1}) \/ (\d{2}) \/ (\d{2}) \/ (\d{2})(\d{0,6})/, '$1 / $2 / $3 / $4 / $5');
    }
    this.upiFinder = value.replace(/\s+/g, "");
  }


  getUpi() {
    if (this.info === '2') {
      return this.upiData;
    } else {
      return this.upiFinder;
    }
  }

  searchInALand() {
    this.checkingData.label = "Checking UPI in Land";
    // this.applicationService.findAllWithPath(environment.landAPI + this.upiFinder)
    this.applicationService.findAllWithPath(environment.landAPI + this.getUpi())
      .subscribe(
        data => {
          if (data && data.data.isUnderRestriction) {
            this.utilService.showNotification(NOTIFICATION_COLOR.warning, "In the checking process, the UPI is under restriction.", " top", "right");
          } else {
            // if (this.info === '1' && (data.data.owners.length > 0) && (data.data.owners[0].idNo && data.data.owners[0].idNo.replace(/\s+/g, "")) !== (this.currentUser.data.user.nationalId.replace(/\s+/g, ""))) {
            //   this.utilService.showNotification(NOTIFICATION_COLOR.warning, "In the checking process, the applicant's national ID was not registered to the UPI you provided.", " top", "right");
            // }
            this.checkingData.label = "";
            if (data && data.data && data.data.upi) {


              if (this.info === '1' && (data.data.owners.length > 0) && (data.data.owners[0].idTypeName === "NATIONAL ID") && (data.data.owners[0].idNo && data.data.owners[0].idNo.replace(/\s+/g, "")) !== (this.currentUser.data.user.nationalId.replace(/\s+/g, ""))) {
                this.utilService.showNotification(NOTIFICATION_COLOR.warning, "In the checking process, the applicant's national ID was not registered to the UPI you provided.", " top", "right");
                this.submitted = false;
              } else {
                // Initialize projectID from BPMIS to be used in development details
                if (this.upiResult.bpmisProjectId) {
                  let tempProjectId = this.upiResult.bpmisProjectId;
                  this.upiResult = data.data;
                  this.upiResult.bpmisProjectId = tempProjectId;
                  this.upiResult.isFromOldSystem = false; // Means this data if from BPMIS System
                  this.upiResult.isRRAVerified = false;
                  // this.upiResult.isRRAVerified = this.rraData.isRRAVerified;
                  // Planned Land Use Format function used to fomart our land use in readable letter
                  this.upiResult.plannedLandUses = this.formattingService.plannedLandUseFormat(this.upiResult.plannedLandUses);
                  if (this.upiResult.plannedLandUses.find((x: any) => x.landUse === 'T')) {
                    this.upiResult.hasRoad = true;
                  } else {
                    this.upiResult.hasRoad = false;
                  }
                  this.modalService.dismissAll();
                  this.backToParent.emit(this.upiResult);
                }
                // Initialize projectID from BPMIS to be used in development details
                else {
                  this.upiResult = data.data;
                  this.upiResult.isFromOldSystem = false; // Means this data if from BPMIS System
                  // Planned Land Use Format function used to fomart our land use in readable letter
                  this.upiResult.plannedLandUses = this.formattingService.plannedLandUseFormat(this.upiResult.plannedLandUses);
                  if (this.upiResult.plannedLandUses.find((x: any) => x.landUse === 'T')) {
                    this.upiResult.hasRoad = true;
                  } else {
                    this.upiResult.hasRoad = false;
                  }
                }
                this.submitted = false;
              }






            } else {
              this.submitted = false;
              this.utilService.showNotification(NOTIFICATION_COLOR.error, 'No data found for this UPI : ' + this.upiFinder, "bottom", "center");
            }
            this.checkingData.label = "";
          }

        }, error => {
          this.submitted = false;
          this.checkingData.label = "";
        }
      )
  }

  // This method called when upi already has a project in new OLD system
  applyOnUpiFromOldSystem() {
    this.upiResult.applicationName = 'N/A'; // selected use
    this.upiResult.seletedCategoryUse = 'N/A'; //category use
    this.upiResult.isFromOldSystem = true;
    this.upiResult.isRRAVerified = false;
    // this.upiResult.isRRAVerified = this.rraData.isRRAVerified;
    this.modalService.dismissAll();
    this.backToParent.emit(this.upiResult)
    this.submitted = false;
  }

  // This method called when upi already has a project in new BPMIS
  continueByCheckIfHasApplication() {

    this.searchInALand()

    // this.modalService.dismissAll();
    // this.backToParent.emit(this.upiResult)
  }




  // routeToOtherRelatedApplication(projectId: any) {
  //   this.modalService.dismissAll();
  //   this.router.navigate(['/account/application/other-application', projectId]);
  // }




  applyOn(data: any, type: any) {
    this.upiResult.applicationName = data.regulation.name; // selected use
    this.upiResult.seletedCategoryUse = type; //category use
    this.modalService.dismissAll();
    this.backToParent.emit(this.upiResult)
  }




}







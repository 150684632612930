import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { QrCodeModule } from 'ng-qrcode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-certificate',
  standalone: true,
  imports: [QrCodeModule, FormsModule, CommonModule],
  templateUrl: './verify-certificate.component.html',
  styleUrls: ['./verify-certificate.component.scss']
})
export class VerifyCertificateComponent {
  certificateDetail: any = {};
  cert: any = 'e';
  isExpired: boolean = false;
  hostname = 'http://' + window.location.hostname + '/verification/certificate/';
  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private utilsService: UtilService
  ) {
    route.params.subscribe((params: any) => {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'certificate/certificate/allData/' + params.id)
        .subscribe(
          data => {
            // if (data[0].statusId === '1') {
            this.certificateDetail = data[0];
            if (this.utilsService.isExpired(this.certificateDetail.expiredDate)) {
              this.isExpired = true;
            } else {
              this.isExpired = false;
            }

            this.applicationService.findAllWithPath(environment.authUrl + 'user-management/agency/code/search?search=' + this.certificateDetail.agencyCode)
              .subscribe(
                data => {
                  this.certificateDetail.agencyName = data.items[0].name;
                }
              )
            // } else {
            //   this.router.navigate(['/account/certificate']);
            //   this.utilsService.showNotification(NOTIFICATION_COLOR.error, "The invoice needs to be paid first", "bottom", "center");
            // }
          },
        )
    })
  }


  changeCertificate(event: any) {
    this.cert = event;
  }
}

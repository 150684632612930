import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent {
  userForm!: UntypedFormGroup;
  currentUser: any = {};
  submitted: boolean = false;

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
  }


  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      firstName: [this.currentUser.data.user.firstName],
      lastName: [this.currentUser.data.user.lastName],
      phoneNumber: [this.currentUser.data.user.phoneNumber],
      email: [this.currentUser.data.user.email],
      userId: [this.currentUser.userId]
    })
  }


  onSubmit() {
    this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/nida/' + this.userForm.value.phoneNumber)
      .subscribe(
        data => {

          if (data.result === this.currentUser.data.user.nationalId) {
            this.submitted = true;
            this.applicationService.patchAssetWithoutParams(this.userForm.value, environment.authUrl + 'user-management/user/updateProfile')
              .subscribe(
                data => {
                  this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your profile updated successfully", "top", "right")
                  this.submitted = false;
                },
                error => {
                  this.submitted = false;
                }
              )
          } else {
            this.utilService.showNotification(NOTIFICATION_COLOR.error, "Phone number " + this.userForm.value.phoneNumber + " is not registered to your national Id " + this.currentUser.data.user.nationalId, "top", "right");
          }
        },
        error => { }
      )


  }
}

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig } from 'src/app/app.config';
import { VALUE } from 'src/app/core/value-enum/value-enum';


export enum NOTIFICATION_COLOR {
  success = 'success-snackbar',
  error = 'error-snackbar',
  warning = 'warning-snackbar',
}

export const GENDERS = [
  { id: 'Female', name: 'Female' },
  { id: 'Male', name: 'Male' }
]



export const spaceFormatValidator = (control: FormControl) => {
  let formattedValue = control.value.replace(/\D/g, ''); // Remove non-digit characters

  // Format with spaces
  if (formattedValue.length > 0) {
    formattedValue = formattedValue.replace(/^(\d{1})(\d{0,4})(\d{0,1})(\d{0,7})(\d{0,3})$/, '$1 $2 $3 $4 $5');
  }

  // Only update the control value if it has changed
  if (control.value !== formattedValue) {
    control.setValue(formattedValue, { emitEvent: false }); // Update control value without emitting change event
  }

  return null; // Return null as there's no validation error
};

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  maxDate!: NgbDateStruct;
  minDate!: NgbDateStruct;
  fileData: any = {};
  fileSize = 50;
  // fileSize = 20;
  documentTypes: any[] = [
    {
      id: '1',
      name: 'National ID'
    },
    {
      id: '2',
      name: 'Passport'
    }
  ];
  public passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/;





  getCategory(value: any): any {
    let category = '';
    const requiredFields = [
      'permitTypeId',
      'buildUpArea',
      'numberOfFloor',
      'capacityInformation',
      'plotSize'
    ];

    // Check if all required fields exist and are not null or undefined
    // for (const field of requiredFields) {
    //   if (value[field] === undefined || value[field] === null) {
    //     console.error(`Missing or invalid field: ${field}`);
    //     return 'Invalid Form Data';
    //   }
    // }

    const formValue = value;

    // Check individual conditions
    if (formValue.permitTypeId === 'TSAP') {
      // return 'CAT1';
      category = 'CAT1'
    } else if (formValue.buildTypeCode === 'FAI' ||
      formValue.buildTypeCode === VALUE.EDUCATION_BUILDING_TYPE ||
      formValue.buildTypeCode === 'ASMB' ||
      formValue.buildTypeCode === 'INSTNAL' ||
      formValue.buildTypeCode === 'MEM' ||
      formValue.buildTypeCode === 'STRG' ||
      formValue.buildTypeCode === 'MISLNS' ||
      formValue.buildTypeCode === 'INST' ||
      formValue.buildTypeCode === 'ASMBLY' ||
      formValue.buildTypeCode === 'INST' ||
      formValue.buildTypeCode === 'HLFFCT' ||
      formValue.buildTypeCode === 'HZRDS' ||
      formValue.buildTypeCode === 'INST' ||
      formValue.buildTypeCode === 'INST' ||
      +formValue.capacityInformation > 500
    ) {
      // return 'CAT5';
      category = 'CAT5'
    } else if (+formValue.numberOfFloor >= 2 || +formValue.buildUpArea > 1500) {
      category = 'CAT4';
    } else if (
      // +formValue.buildUpArea < 1500 ||
      +formValue.numberOfFloor >= 2 &&
      (+formValue.capacityInformation > 50 &&
        +formValue.capacityInformation <= 500
      )) {


      category = 'CAT4';
    } else if (
      // +formValue.buildUpArea < 1500 ||
      (!formValue.numberOfFloor ||
        +formValue.numberOfFloor < 2) &&
      (+formValue.capacityInformation > 50 &&
        +formValue.capacityInformation <= 500
      )) {


      category = 'CAT4';
    } else if (+formValue.numberOfFloor === 1) {
      category = 'CAT3';
    } else if ((+formValue.buildUpArea > 200 && +formValue.buildUpArea <= 1500) &&
      (+formValue.numberOfFloor === 1
        || +formValue.numberOfFloor === 0
      ) &&
      (!formValue.capacityInformation || +formValue.capacityInformation > 15 ||
        +formValue.numberOfFloor <= 50
      )) {
      category = 'CAT3';
    } else if ((+formValue.buildUpArea > 200 && +formValue.buildUpArea <= 1500) &&
      +formValue.numberOfFloor === 1 &&
      (+formValue.capacityInformation > 15 ||
        +formValue.numberOfFloor <= 50
      )) {
      category = 'CAT3';
    } else if (+formValue.numberOfFloor === 0 && (+formValue.capacityInformation > 15 && +formValue.capacityInformation <= 50)) {
      category = 'CAT3';
    } else {
      category = 'CAT2';
    }

    return category;
  }


  getDecodedToken(): any {
    const token = this.getToken();
    if (token) {
      try {
        // return jwtDecode<IAuth>(token);
        return token;
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  getToken(): any {
    // return localStorage.getItem(this.appConfig.JWT_Token)
    //   ? JSON.parse(localStorage.getItem(this.appConfig.JWT_Token) as any)?.token
    //   : null;

    return localStorage.getItem(this.appConfig.JWT_Token)
      ? JSON.parse(localStorage.getItem(this.appConfig.JWT_Token) as any)?.access_Token.token
      : null;
  }

  tableArraySelector = [
    { id: 10 },
    { id: 50 },
    { id: 100 },
    { id: 200 },
    { id: 500 },
    { id: 1000 },
  ]
  constructor(
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private appConfig: AppConfig
  ) {

  }


  removeSpaceInString(originalString: any) {

    return originalString ? originalString.replace(/\s+/g, '') : "";
  }

  showNotification(colorName: any, text: any, placementFrom: any, placementAlign: any) {
    this.snackBar.open(text, 'Close', {
      duration: 5000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: [colorName],
    });
  }

  showFewMinutesNotification(colorName: any, text: any, placementFrom: any, placementAlign: any) {
    this.snackBar.open(text, 'Close', {
      duration: 3000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: [colorName],
    });
  }


  checkValidations(control: any, type: any) {
    switch (type) {
      case 'special-character':
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);;
      case 'number':
        return /\d/.test(control.value);
      case 'lowercase':
        return /[a-z]/.test(control.value);
      case 'uppercase':
        return /[A-Z]/.test(control.value);
      case 'length':
        return control.value.length >= 8 && control.value.length <= 16;
      default:
        return false
    }
  }



  convertToDateFormat(date: any) {
    return date.year + '-' + ((+date.month < 10) ? '0' + date.month : date.month) + '-' + ((+date.day < 10) ? '0' + date.day : date.day);
  }


  convertSlashDateToDateFormat(date: any) {
    let slashDate = date.split('/');
    return slashDate[2] + '-' + slashDate[1] + '-' + slashDate[0];
  }

  getCheckFileExtension(file: any) {
    return file.target.files[0].name.split(".")[1];
  }








  getFileDetails(e: any): any {
    this.fileData.fileName = e.target.files[0].name;
    this.fileData.fileNameDisplay = e.target.files[0].name;
    this.handleFileSelected(e);
    if (this.fileData.fileType !== 'excel' && this.getCheckFileExtension(e).toLowerCase() === 'pdf') {
      this.fileData.myFiles = [];
      for (var i = 0; i < e.target.files.length; i++) {
        this.fileData.myFiles.push(e.target.files[i]);
      }
      if (this.fileData.size > 3) {

        this.showNotification(
          NOTIFICATION_COLOR.error,
          "Error: " + 'File will not be saved because it is too big, try 50MB of size',
          "bottom",
          "center"
        );
        return null;
      } else {
        // Return file data
        this.fileData.file = e.target.files;
        return this.fileData;
      }
    } else {
      this.showNotification(
        NOTIFICATION_COLOR.error,
        "Error: " + 'PDF File allowed only',
        "bottom",
        "center"
      );
      return null;
    }
  }


  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }



  isExpired(dateString: any): boolean {
    const providedDate: Date = new Date(dateString);

    // Get the current date
    const currentDate: Date = new Date();

    // Check if the provided date is before the current date (i.e., expired)
    return providedDate < currentDate;
  }



  // Function to decode Base64 and check if it starts with the signature of an Excel file
  isExcelFile(base64String: string): boolean {
    // Decode the Base64 string
    const byteCharacters = atob(base64String);

    // Check the first 4 bytes
    const byte1 = byteCharacters.charCodeAt(0);
    const byte2 = byteCharacters.charCodeAt(1);
    const byte3 = byteCharacters.charCodeAt(2);
    const byte4 = byteCharacters.charCodeAt(3);

    // ZIP file signature: 0x50 0x4B 0x03 0x04
    return byte1 === 0x50 && byte2 === 0x4B && byte3 === 0x03 && byte4 === 0x04;
  }

  downloadExcel(base64Excel: any) {
    if (this.isExcelFile(base64Excel)) {
      const byteCharacters = atob(base64Excel);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'boq.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } else {
      console.error('The provided Base64 string is not a valid Excel file.');
    }
  }



  cleanUsageData() {
    localStorage.removeItem('upiDetailsFromLand');

  }



  whichTypeOfUser(inputData: any): any {
    if (inputData.accountTypeId === '1') {
      return 'LO';
    }
    if (inputData.accountTypeId === '2') {
      return 'ENG';
    }
    if (inputData.accountTypeId === '3') {
      // return 'ARCHT';
      return 'ARC';
    }
  }

  whichTypeOfRole(inputData: any): any {
    if (inputData.accountTypeId === '1') {
      return 'APP';
    } else {
      return 'ENG'
    }
  }



  returnToNumber(stringValue: any) {
    return parseInt(stringValue.replace(/,/g, ''), 10);
  }



  async encryptString(data: string, password: string): Promise<string> {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const keyBuffer = await crypto.subtle.digest('SHA-256', encoder.encode(password));
    const key = await crypto.subtle.importKey(
        'raw', keyBuffer, { name: 'AES-GCM' }, false, ['encrypt']
    );
    const iv = crypto.getRandomValues(new Uint8Array(12)); // 12-byte IV
    const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        key,
        dataBuffer
    );
    return `${btoa(String.fromCharCode(...iv))}:${btoa(String.fromCharCode(...new Uint8Array(encrypted)))}`;
  }


  async decryptString(encryptedData: string, password: string): Promise<string> {
    const [ivStr, dataStr] = encryptedData.split(':');
    const iv = Uint8Array.from(atob(ivStr), char => char.charCodeAt(0));
    const encryptedDataBuffer = Uint8Array.from(atob(dataStr), char => char.charCodeAt(0));

    const encoder = new TextEncoder();
    const keyBuffer = await crypto.subtle.digest('SHA-256', encoder.encode(password));
    const key = await crypto.subtle.importKey(
        'raw', keyBuffer, { name: 'AES-GCM' }, false, ['decrypt']
    );
    const decrypted = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        key,
        encryptedDataBuffer
    );
    return new TextDecoder().decode(decrypted);
  }




}



export const ACTIVITIES = [
  {
    code: 'MENU-0001',
    name: 'View Dashboard',
    isAllowToSeeDashboard: false
  }
]


// 1. create activities JSON

// {
//   activityCode: "",
//   activityName: "",
//   activityType: '' //  menu, sub menu, actions, view lists \
// }

// 2. create permission grant/revoke JSON

// {
//   roleId: "",
//   activityId: "",
//   isAllow: true/false
// }



// 3. Get roles by userId


// 4. Get permissions by userId/roleId



